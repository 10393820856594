import { MaybeFC } from 'components/common/maybe';
import { BottomNavigationBar } from 'components/widgets/footer';
import { AppHeader } from 'components/widgets/header';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import useKurosimNavigation, {
  TabNavigation,
} from 'hooks/use-kurosim-navigation';

import { AppActionType, IAppAction } from './types';
import SideNavigationAction from '../side-navigation/actions';

function useAppActionRouting(props: IAppAction) {
  const { push, replace, currentRoute } = useKurosimNavigation();
  const current = currentRoute();
  return {
    onClick() {
      props.onClick?.();
      if (props.type === AppActionType.Navigation) {
        replace(props.route as any);
        TabNavigation.update(current.pathname, current);
      } else if (props.route) {
        push(props.route as any);
      }
    },
    active:
      props?.route === current.pathname ||
      !!props.routePrefix?.some((prefix) =>
        current.pathname.startsWith(prefix),
      ),
  };
}

function usePlaceholderProvideDataHook(props: IAppAction): IAppAction {
  return props;
}

export default function ResponsiveAppActionComponent(rawProps: IAppAction) {
  const { useProvideData = usePlaceholderProvideDataHook } = rawProps;
  const small = useIsSmallScreen();
  const props = useProvideData(rawProps);
  const { onClick, active } = useAppActionRouting(props);

  if (props.hidden) {
    return null;
  }

  if (!small) {
    return (
      <>
        <SideNavigationAction
          icon={props.icon}
          active={active}
          onClick={onClick}
          customization={props.sidenavCustomization}
          indicator={props.indicator}
        >
          {props.children}
        </SideNavigationAction>
        {props.additionalComponent}
      </>
    );
  }

  if (props.type === AppActionType.Convenience) {
    return null;
  }
  if (props.type === AppActionType.Navigation) {
    return (
      <>
        <BottomNavigationBar.Action
          icon={(iconProps) => (
            <MaybeFC props={iconProps}>{props.icon}</MaybeFC>
          )}
          label={props.children as any}
          to={props.route}
        />
        {props.additionalComponent}
      </>
    );
  }
  return (
    <>
      <AppHeader.Action
        customization={props.headerCustomization}
        tooltip={
          props.label
            ? props.label
            : typeof props.children === 'string'
              ? props.children
              : undefined
        }
        indicator={props.indicator}
        onClick={onClick}
      >
        {props.icon}
      </AppHeader.Action>
      {props.additionalComponent}
    </>
  );
}
