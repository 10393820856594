import React from 'react';

export enum BrowserStorageType {
  Local = 'local',
  Session = 'session',
}

interface BrowserStorageSyncProps<T> {
  value: T | null | undefined;
  key: string;
  isValid?(value: T | null | undefined): boolean;
  fallback: T;
}

function getBrowserStorage(type: BrowserStorageType): Storage {
  if (type === BrowserStorageType.Local) {
    return localStorage;
  } else {
    return sessionStorage;
  }
}

export function useBrowserStorageSync<T>(
  type: BrowserStorageType,
  props: BrowserStorageSyncProps<T>,
) {
  const { value, key, isValid, fallback } = props;
  React.useEffect(() => {
    const storage = getBrowserStorage(type);
    const valid = isValid ? isValid(value) : !!value;
    if (valid) {
      storage.setItem(key, JSON.stringify(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return React.useMemo(() => {
    if (value) return value;
    const storage = getBrowserStorage(type);
    const json = storage.getItem(key);
    if (json) {
      try {
        return JSON.parse(json);
      } catch {
        storage.removeItem(key);
      }
    }
    return fallback;
  }, [fallback, key, type, value]);
}
