import { ScrollArea, ScrollAreaProps } from '@mantine/core';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import ErrorViewComponent from 'components/common/error-view-component';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import useKurosimParentScroll from 'hooks/use-kurosim-parent-scroll';
import useGetParentRef, { ParentRefType } from 'hooks/use-parent-ref';
import MobileContainer from 'modules/components/mobile-container';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import React from 'react';
import structuralStyles from 'styles/layout.css';

import AppLayoutStyles from './app.styles.css';
import { DefaultAppLayoutActionsProvider } from '../actions/context';
import { SafeAreaContext } from '../server-props/safe-area';
import SideNavigation from '../side-navigation';

interface AppLayoutCustomization {
  root?: CustomizationUnit<React.ComponentProps<'div'>>;
  header?: CustomizationUnit<React.ComponentProps<'header'>>;
  body?: CustomizationUnit<React.ComponentProps<'div'>>;
  main?: CustomizationUnit<React.ComponentProps<'main'>>;
  footer?: CustomizationUnit<React.ComponentProps<'footer'>>;
}

interface AppLayoutProps {
  Header?: React.ReactNode;
  Footer?: React.ReactNode;
  children?: React.ReactNode;
  customization?: AppLayoutCustomization;
  noSideNavigation?: boolean;
}

function AppLayout(props: AppLayoutProps) {
  const { Header, Footer, children, customization, noSideNavigation } = props;
  const safeArea = React.useContext(SafeAreaContext);
  const isMobile = useIsSmallScreen();

  const refScroll = useKurosimParentScroll();
  const refLoading = useGetParentRef(ParentRefType.Loading);
  const refFooter = useGetParentRef(ParentRefType.Footer);
  const Main = (
    <ErrorBoundary
      errorComponent={(props) => (
        <ErrorViewComponent
          refetch={props.reset}
          title={props.error.name}
          message={props.error.message}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );

  if (isMobile || noSideNavigation) {
    return (
      <DefaultAppLayoutActionsProvider>
        <div
          {...applyCustomization(
            {
              className: AppLayoutStyles.root,
              style: {
                paddingTop: 'env(safe-area-inset-top)',
                paddingLeft: safeArea.left,
                paddingRight: safeArea.right,
                paddingBottom: 'env(safe-area-inset-bottom)',
              },
            },
            [customization?.root],
          )}
        >
          <header {...applyCustomization({}, [customization?.header])}>
            {Header}
          </header>
          <main
            ref={(ref) => {
              refLoading.current = ref;
              refScroll.current = ref;
            }}
            {...applyCustomization(
              {
                className: AppLayoutStyles.main,
              },
              [customization?.main],
            )}
          >
            <MobileContainer
              {...applyCustomization(
                {
                  style: {
                    height: '100%',
                  },
                },
                [customization?.body],
              )}
              // style={mainStyle}
            >
              {Main}
            </MobileContainer>
          </main>
          <footer
            {...applyCustomization(
              {
                className: AppLayoutStyles.onlyLast,
              },
              [customization?.footer],
            )}
            ref={refFooter}
          >
            {Footer}
          </footer>
        </div>
      </DefaultAppLayoutActionsProvider>
    );
  }

  return (
    <DefaultAppLayoutActionsProvider>
      <SideNavigation>
        <header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 4,
            backgroundColor: 'white',
          }}
        >
          {Header}
        </header>
        <main
          {...applyCustomization(
            {
              style: {
                height: '100%',
                minHeight: Header ? 'calc(100dvh - 65px)' : '100dvh',
                position: 'relative',
              },
            },
            [customization?.body],
          )}
          ref={(ref) => {
            refLoading.current = ref;
          }}
        >
          {Main}
        </main>
        <footer
          {...applyCustomization(
            {
              style: {
                position: 'sticky',
                bottom: 0,
                zIndex: 4,
                backgroundColor: 'white',
              },
              className: AppLayoutStyles.onlyLast,
            },
            [customization?.footer],
          )}
          ref={refFooter}
        >
          {Footer}
        </footer>
      </SideNavigation>
    </DefaultAppLayoutActionsProvider>
  );
}

/** For lists which elements must not be cut off by  */
export const AppLayoutHorizontalScrollArea = React.forwardRef<
  HTMLDivElement,
  ScrollAreaProps
>((props, ref) => {
  return (
    <ScrollArea type="never" {...props} viewportRef={ref}>
      <div className={structuralStyles.padding({ horizontal: 16 })}>
        {props?.children}
      </div>
    </ScrollArea>
  );
});

export default AppLayout;
