import { SaleInvoicePreparePaymentModel } from 'api-hooks/sale-invoice';
import { DataPlanTypeEnum } from 'common/constants/enum';
import { string2money } from 'common/utils/string';
import { Translate } from 'next-translate';

export const trackViewItemCountry = (slug: string) => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'View Item Country',
      country: slug,
    });
  }
};

export const trackCompleteRegistration = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Complete Registration',
    });
  }
};

export const trackAddToCart = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Add To Cart',
    });
  }
};

export const trackInitiateCheckout = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Initiate Checkout',
    });
  }
};

export const trackInitiatePayment = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Initiate Payment',
    });
  }
};

export const transformPaymentInfo = (
  data: SaleInvoicePreparePaymentModel,
  t: Translate,
) => {
  const items = (data.items || []).map((item) => {
    let validity = '';
    if (item.metadata.validityDays > 1) {
      validity = `${item.metadata.validityDays} days`;
    } else {
      validity = `${item.metadata.validityDays} day`;
    }

    let quotaGB = '';
    if (item.metadata.quotaInGb !== null) {
      quotaGB = `${item.metadata.dataType === DataPlanTypeEnum.Unlimited ? ' | FUP' : ' |'} ${string2money(item.metadata.quotaInGb)} GB`;
    }

    let simCondition = '';
    if (item.sim === null) {
      simCondition = ` | ${t('common:new_esim')}`;
    } else {
      simCondition = ` | ${item.sim.label ? t('common:recharge_extra', { extra: item.sim.label }) : t('sim:recharge_esim')}`;
    }

    const variantText = `${validity}${quotaGB}${simCondition}`;
    return {
      id: item.id,
      discount: item.discountedPrice,
      item_name: item.metadata.name,
      item_variant: variantText,
      price: item.sellPrice,
      currency: item.currencyCode,
      quantity: item.qty,
    };
  });
  return {
    items,
    coupon: data.referral?.code,
    currency: 'IDR',
    payment_type: data.paymentMethod?.type,
    price: data.totalPayment,
  };
};

export const trackAddPaymentInfo = ({
  value,
  payment_type,
  items,
  currency = 'IDR',
  coupon,
}: {
  value: number;
  payment_type?: string;
  items: any[];
  coupon?: string;
  currency?: string;
}) => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Add Payment Info',
      currency,
      value,
      payment_type,
      items,
      coupon,
    });
  }
};
