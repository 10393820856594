import { useGetBadges, notificationKey } from 'api-hooks/notification/query';
import { queryClient } from 'common/api/query-client';
import { useAuth, useLogout } from 'common/auth';
import { NavigationRoutes } from 'common/routes';
import { BottomSheetRemote } from 'components/widgets/bottom-sheet';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import {
  CrispContext,
  LiveChatBottomSheet,
} from 'modules/profile/screens/live-chat';
import { useRouter } from 'next/router';
import React from 'react';

import { IAppAction } from './types';

export function useNotificationAppActionDataProvider(
  props: IAppAction,
): IAppAction {
  const { isAuthenticated } = useAuth();
  const badges = useGetBadges({
    staleTime: 60000,
  });
  const { pathname } = useRouter();
  const notificationCount = badges?.data?.data?.notification.count;
  const small = useIsSmallScreen();
  return {
    ...props,
    hidden:
      !isAuthenticated ||
      (small && pathname === NavigationRoutes.Notifications),
    indicator: notificationCount,
    onClick() {
      props.onClick?.();
      queryClient.invalidateQueries(notificationKey.badges());
    },
  };
}

export function useLiveChatAppActionDataProvider(
  props: IAppAction,
): IAppAction {
  const remote = React.useRef<BottomSheetRemote | null>(null);
  const { unreadCount } = React.useContext(CrispContext);
  return {
    ...props,
    indicator: unreadCount,
    additionalComponent: <LiveChatBottomSheet ref={remote} />,
    onClick() {
      remote.current?.open();
    },
  };
}

export function useLogoutAppActionDataProvider(props: IAppAction): IAppAction {
  const { openLogout, DialogLogout } = useLogout({});
  const { isAuthenticated } = useAuth();
  return {
    ...props,
    hidden: !isAuthenticated,
    additionalComponent: DialogLogout,
    onClick: openLogout,
  };
}
