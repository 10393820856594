import { useUncontrolled } from '@mantine/hooks';
import { IconProps } from '@phosphor-icons/react';
import classNames from 'classnames';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import colors from 'common/styles/colors';
import Text from 'components/elements/text';
import useKurosimNavigation, {
  TabNavigation,
} from 'hooks/use-kurosim-navigation';
import MobileContainer, {
  MobileContainerProps,
} from 'modules/components/mobile-container';
import React, { useId } from 'react';
import structuralStyles from 'styles/layout.css';

import BottomNavbarStyles from './navigation.styles.css';

interface BottomNavigationBarCustomization {
  root?: CustomizationUnit<React.ComponentProps<'div'>> & {
    noBorder?: boolean;
  };
}

interface BottomNavigationBarProps<TKeys extends string = string> {
  customization?: BottomNavigationBarCustomization;
  value?: TKeys;
  defaultValue?: TKeys;
  onChange?(value: TKeys): void;
  children: React.ReactNode;
  mobileContainerProps?: MobileContainerProps;
}

interface BottomNavigationBarActionProps<TKeys extends string = string> {
  icon(props: IconProps): React.ReactNode;
  label: string;
  value?: TKeys;
  to?: NavigationRoutes;
}

type BottomNavigationBarContextType = {
  value: string | null;
  setValue: React.Dispatch<React.SetStateAction<string | null>>;
};
const BottomNavigationBarContext =
  React.createContext<BottomNavigationBarContextType>({
    value: null,
    setValue() {},
  });

function BottomNavigationBar<TKeys extends string = string>(
  props: BottomNavigationBarProps<TKeys>,
) {
  const {
    value: _value,
    defaultValue,
    onChange,
    children,
    customization,
    mobileContainerProps,
  } = props;
  const [value, setValue] = useUncontrolled({
    value: _value,
    defaultValue,
    onChange,
  });
  return (
    <BottomNavigationBarContext.Provider value={{ value, setValue }}>
      <div
        {...applyCustomization(
          {
            className: classNames(
              BottomNavbarStyles.root,
              customization?.root?.noBorder
                ? undefined
                : BottomNavbarStyles.footerBorder,
            ),
          },
          [customization?.root],
        )}
      >
        <MobileContainer
          className={classNames(
            structuralStyles.flexbox({ direction: 'row', justify: 'between' }),
          )}
          {...mobileContainerProps}
        >
          {children}
        </MobileContainer>
      </div>
    </BottomNavigationBarContext.Provider>
  );
}

function BottomNavbarAction<TKeys extends string = string>(
  props: BottomNavigationBarActionProps<TKeys>,
) {
  const { icon, label, value, to } = props;
  const { value: selected, setValue: setSelected } = React.useContext(
    BottomNavigationBarContext,
  );
  const id = useId();

  const isActive = value ? selected === value : to ? selected === to : false;
  const theme = isActive
    ? ('foregroundPrimary' as const)
    : ('foregroundTertiary' as const);

  const { currentRoute, replace } = useKurosimNavigation();

  // const handleClickAnchor = (pageId: string) => {
  //
  // };

  return (
    <button
      type="button"
      tabIndex={0}
      aria-labelledby={id}
      className={BottomNavbarStyles.action}
      onClick={() => {
        const current = currentRoute();

        TabNavigation.update(current.pathname, current);
        if (value) {
          setSelected(value);
        }

        if (!to) return;
        replace(to as any);
      }}
    >
      {icon({
        size: 24,
        color: colors[theme],
        weight: isActive ? 'fill' : 'regular',
      })}
      <Text
        wrap={false}
        textColor={theme}
        id={id}
        textVariant={isActive ? 'body2Medium' : 'body2Regular'}
      >
        {label}
      </Text>
    </button>
  );
}

(BottomNavigationBar as any).Action = BottomNavbarAction;

export default BottomNavigationBar as typeof BottomNavigationBar & {
  Action: typeof BottomNavbarAction;
};
