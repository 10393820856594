import React, { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FormContext } from '../form/context';
import PinInput, { PinInputProps } from '../pin-input';

export interface PinInputFieldProps extends PinInputProps {
  name: string;
  type: 'pin';
  submitWhenFilled?: boolean;
}

export default function PinInputField(props: PinInputFieldProps) {
  const {
    name,
    disabled: _disabled,
    readOnly,
    type,
    onFilled,
    submitWhenFilled,
    ...rest
  } = props;

  const context = useContext(FormContext);
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({ name, control });

  const disabled = !context.editable || readOnly || _disabled;
  const error = !!fieldState?.error?.message;

  const ref = React.useRef<HTMLInputElement | null>(null);

  return (
    <PinInput
      {...rest}
      {...field}
      ref={ref}
      error={error}
      disabled={disabled}
      onFilled={(value) => {
        onFilled?.(value);
        if (submitWhenFilled) {
          let formElement: HTMLElement | undefined | null = ref.current;
          // Find form parent
          while (formElement && formElement.tagName.toLowerCase() !== 'form') {
            formElement = formElement.parentElement;
          }
          if (!formElement) {
            return;
          }

          const form: HTMLFormElement = formElement as HTMLFormElement;
          /*
          https://stackoverflow.com/questions/56694597/how-can-i-prevent-form-submission-page-navigation-on-artificial-submit-event-i/56695472#56695472
          https://bugzilla.mozilla.org/show_bug.cgi?id=1370630
          Required properties on submit event:
          - Cancelable: Chrome doesn't execute default form handler for an untrusted event, but Firefox does.
          - Bubbles: dunno why but this is necessary
          
          What had happened: preventDefault did not successfully prevent default behavior in Firefox, which causes the form to execute its default behavior which is performing a full page reload. Since this is usually used in /verify, and that endpoint requires an Inter-Page data payload to be available to work, ``useInterPageData`` forces a navigation into the previous page, and since there's no previous page, back to Store it is!
          */
          form.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );
        }
      }}
    />
  );
}
