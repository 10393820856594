/* eslint-disable @next/next/no-img-element */
import { AssetPaths } from 'common/constants/assets';
import * as React from 'react';

export default function FlagCaribbeanIslands(
  props: React.ComponentProps<'img'>,
) {
  return (
    <img
      src={AssetPaths.RegionCaribbeanIslands}
      alt="caribbean islands"
      {...props}
    />
  );
}
