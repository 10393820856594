import { Loader } from '@mantine/core';
import { ImageSquareIcon } from 'common/assets';
import { ResizeImage } from 'common/helpers/image';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import { fileStyles } from './styles.css';
import { onDrop } from './utils';
import Text from '../text';

export const ACCEPTED_FILE =
  'application/pdf,image/*,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessing';

export interface MainFilePickerProps {
  id?: string;
  name?: string;
  accept?: string;
  isError?: boolean;
  disabled?: boolean;
  resizeWidth: number;
  isMultiple?: boolean;
  resizeHeight: number;
  resizeImage?: boolean;
  onPicked: (files: { filename: string; url: string }[]) => void;
}

export default function MainFilePicker(props: MainFilePickerProps) {
  const {
    isError,
    onPicked,
    disabled,
    isMultiple,
    resizeWidth,
    resizeImage,
    resizeHeight,
  } = props;
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const ResizeImageFunc = React.useCallback(
    async (file, type) => {
      const result: File = (await ResizeImage({
        type,
        width: resizeWidth,
        imageToResize: file,
        height: resizeHeight,
      })) as File;
      return result;
    },
    [resizeHeight, resizeWidth],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) =>
      onDrop({
        onPicked,
        isMultiple,
        resizeImage,
        setIsUploading,
        ResizeImageFunc,
        acceptedFiles: files,
      }),
  });

  const rootProps = getRootProps({ refKey: 'ref' });

  if (disabled) {
    return null;
  }

  return (
    <div
      {...rootProps}
      className={fileStyles.mainDropzoneContainer({ error: isError })}
    >
      <div className={fileStyles.borderContainer()}>
        {isUploading ? (
          <>
            <Loader size={30} color="blue" />
          </>
        ) : (
          <>
            <div className={fileStyles.textContainer}>
              <ImageSquareIcon size={24} />
              <Text style={{ marginLeft: '4px' }}>
                {t('common:upload_file')}
              </Text>
            </div>
            <input
              {...getInputProps()}
              id={props.id}
              name={props.name}
              disabled={disabled}
              accept={props.accept ?? ACCEPTED_FILE}
            />
          </>
        )}
      </div>
    </div>
  );
}
