import config from 'common/config';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import notification from 'common/helpers/notification';
import { camelizeKeys } from 'humps';
import ky from 'ky';

interface Props {
  acceptedFiles: File[];
  setIsUploading?: (value: React.SetStateAction<boolean>) => void;
  onPicked: (files: { filename: string; url: string }[]) => void;
  resizeImage?: boolean;
  ResizeImageFunc?: (file: any, type: any) => Promise<File>;
  isMultiple?: boolean;
}

interface OnUploadProps
  extends Omit<
    Props,
    'acceptedFiles' | 'setIsUploading' | 'onPicked' | 'isMultiple'
  > {
  fileToUpload: File;
}

function getFileUploadContentType(type: string) {
  switch (type) {
    case 'image/svg+xml':
      return 'image/svg%2Bxml';
    default:
      return type;
  }
}

async function uploadFileParam({
  contentType,
  body,
  accessToken,
  url,
  method,
}) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': contentType,
  };

  const result = await ky(url, {
    headers,
    method,
    body,
  });

  return result.json();
}

async function onUpload(props: OnUploadProps) {
  const { fileToUpload, resizeImage, ResizeImageFunc } = props;

  const method = 'POST';
  const url = config.apiEndpoint + '/api/user/files/upload';
  const accessToken = JSON.parse(
    localStorage.getItem(LocalStorageKeys.AuthStorage) || '',
  )?.accessToken;
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': fileToUpload.type,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  };

  const splits = fileToUpload.name.split('.');
  const fileExtension = splits[splits?.length - 1].toLowerCase();
  const blobUrl = URL.createObjectURL(fileToUpload).concat(`.${fileExtension}`);
  const fileBlob = Object.assign(fileToUpload, { preview: blobUrl });

  let result: any = await uploadFileParam({
    url,
    method,
    accessToken,
    body: await fileToUpload.arrayBuffer(),
    contentType: getFileUploadContentType(fileToUpload.type),
  });

  result = await camelizeKeys(result);
  if (!result) {
    throw new Error('Data is undefined');
  }
  const { name: filename } = result;

  if (resizeImage && ResizeImageFunc) {
    const file = await ResizeImageFunc(fileBlob, fileToUpload.type);
    const uploadResponse = await fetch(url, {
      method,
      body: await file.arrayBuffer(),
      headers,
    });
    const uploadResponseBody = await uploadResponse.text();
    if (!uploadResponse.ok) {
      throw new Error(uploadResponseBody);
    }

    return {
      filename,
      url: blobUrl,
    };
  }

  const uploadResponse = await fetch(url, {
    method,
    body: await fileToUpload.arrayBuffer(),
    headers,
  });
  const uploadResponseBody = await uploadResponse.text();
  if (!uploadResponse.ok) {
    throw new Error(uploadResponseBody);
  }

  return {
    filename,
    url: blobUrl,
  };
}

export async function onDrop(props: Props) {
  const {
    acceptedFiles,
    setIsUploading,
    onPicked,
    resizeImage,
    ResizeImageFunc,
    isMultiple,
  } = props;

  try {
    setIsUploading?.(true);
    const funcKey = {
      resizeImage,
      ResizeImageFunc,
    };

    if (isMultiple) {
      const fileToUpload = acceptedFiles;
      const result = await Promise.all(
        fileToUpload.map(async (file) => {
          return onUpload(
            await {
              fileToUpload: file,
              ...funcKey,
            },
          );
        }),
      );
      onPicked(result);
    } else {
      const fileToUpload = acceptedFiles[0];
      const result = await Promise.all([
        onUpload(
          await {
            fileToUpload,
            ...funcKey,
          },
        ),
      ]);
      onPicked(result);
    }
  } catch (e: any) {
    console.error(e);
    notification.error({ message: e?.message });
  } finally {
    setIsUploading?.(false);
  }
}
