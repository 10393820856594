import { useContext } from 'react';
import { useController } from 'react-hook-form';

import MainFilePicker from './main-file-picker';
import PreviewFilePicker from './preview-file-picker';
import { FormContext } from '../form/context';
import FormControl from '../form-control';
import ReadOnlyText from '../read-only-text';

export interface SingleFilePickerProps {
  name: string;
  type: 'file';
  ratio?: number;
  label?: string;
  maxWidth?: string;
  noMargin?: boolean;
  disabled?: boolean;
  required?: boolean;
  previewName: string;
  resizeWidth: number;
  resizeImage?: boolean;
  resizeHeight: number;
  onAfterChange?: (file: { filename: string; url: string }) => void;
}

export default function SingleFilePicker(props: SingleFilePickerProps) {
  const {
    ratio,
    label,
    maxWidth,
    required,
    noMargin,
    resizeWidth,
    resizeImage,
    resizeHeight,
    onAfterChange,
  } = props;

  const context = useContext(FormContext);
  const { field, fieldState } = useController({ name: props.name });
  const { field: previewField, fieldState: previewFieldState } = useController({
    name: props.previewName,
  });

  const disabled = props.disabled || !context.editable;

  if (!context.editable && !previewField.value && !field.value) {
    return <ReadOnlyText value="-" label={label} />;
  }

  const hasValue = !!field.value && !!previewField.value;

  const _onPicked = (files: { filename: string; url: string }[]) => {
    const file = files[0];
    field.onChange(file.filename);
    previewField.onChange(file.url);
    onAfterChange?.(file);
  };

  return (
    <FormControl
      label={label}
      required={required}
      noMargin={noMargin}
      error={fieldState?.error?.message || previewFieldState?.error?.message}
    >
      <div style={{ maxWidth }}>
        {hasValue ? (
          <PreviewFilePicker
            ratio={ratio}
            disabled={disabled}
            onPicked={_onPicked}
            resizeImage={resizeImage}
            resizeWidth={resizeWidth}
            resizeHeight={resizeHeight}
            preview={previewField.value}
            onDelete={() => {
              field.onChange(null);
              previewField.onChange(null);
            }}
          />
        ) : (
          <MainFilePicker
            disabled={disabled}
            onPicked={_onPicked}
            resizeImage={resizeImage}
            resizeWidth={resizeWidth}
            resizeHeight={resizeHeight}
            isError={!disabled && !!fieldState?.error?.message}
          />
        )}
      </div>
    </FormControl>
  );
}
