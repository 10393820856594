import { AssetPaths } from 'common/constants/assets';
import { NavigationRoutes } from 'common/routes';
import { categorize } from 'common/utils/iterable';
import Separator from 'components/common/separator';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import useKurosimParentScroll from 'hooks/use-kurosim-parent-scroll';
import Image from 'next/image';
import React from 'react';

import sideNavigationStyles from './side-navigation.styles.css';
import ResponsiveAppActionComponent from '../actions';
import { AppLayoutActionsContext } from '../actions/context';
import { AppActionType } from '../actions/types';

interface SideNavigationProps {
  children: React.ReactNode;
}

export default function SideNavigation(props: SideNavigationProps) {
  const { children } = props;

  const { replace } = useKurosimNavigation();

  const isMobile = useIsSmallScreen();
  const scrollRef = useKurosimParentScroll();

  const allMenus = React.useContext(AppLayoutActionsContext);
  const { mainMenus = [], otherMenus = [] } = categorize(allMenus, (menu) =>
    menu.type === AppActionType.Navigation ? 'mainMenus' : 'otherMenus',
  );
  if (isMobile) return children;

  return (
    <main
      // Literal string class names are just comments for Ferdy and does not contain any styling information
      className={`main-container ${sideNavigationStyles.mainContainer}`}
      ref={(ref) => {
        scrollRef.current = ref;
      }}
    >
      <div
        className={`desktop-content-wrapper ${sideNavigationStyles.desktopContentWrapper}`}
      >
        <div
          className={`side-menu-desktop ${sideNavigationStyles.sideMenuDesktop}`}
        >
          <Image
            className={`colorful-desktop-tint ${sideNavigationStyles.colorfulDesktopTint}`}
            src={AssetPaths.ColorfulDesktopTint}
            width={400}
            height={325}
            alt="colorful-tint"
          />
          <div
            className={`brand-logo ${sideNavigationStyles.brandLogo}`}
            onClick={() => replace(NavigationRoutes.Store)}
          >
            <Image
              src={AssetPaths.PrimaryLogoLight}
              alt="kurosim"
              width={120}
              height={18}
            />
          </div>
          {mainMenus.map((menu) => {
            return <ResponsiveAppActionComponent {...menu} key={menu.key} />;
          })}
          <Separator gap={16} />
          {otherMenus.map((menu) => {
            return <ResponsiveAppActionComponent {...menu} key={menu.key} />;
          })}
        </div>
        <Separator gap={24} />
        <div
          className={`main-content-desktop ${sideNavigationStyles.mainContentDesktop}`}
        >
          {children}
        </div>
      </div>
    </main>
  );
}
