import { useLocalStorage } from '@mantine/hooks';
import { SearchIcon } from 'common/assets';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import colors from 'common/styles/colors';
import TextInput from 'components/elements/text-input';
import invariant from 'invariant';
import { useRouter } from 'next/router';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import AppHeaderStyles from './app.styles.css';

interface SearchBarProps {
  onClick?: () => void;
  placeholder?: string;
  disabled?: boolean;
}

export const SearchContext = React.createContext<{
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}>({
  search: '',
  setSearch: () => {},
});

export function SearchProvider({ children }) {
  const [search, setSearch] = React.useState('');
  const { query } = useRouter();
  const { q } = query;
  const values = React.useMemo(() => {
    return { search, setSearch };
  }, [search]);

  //sync query params of q
  React.useEffect(() => {
    setSearch((q as string) ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchContext.Provider value={values}>{children}</SearchContext.Provider>
  );
}

export function useSearch() {
  const context = React.useContext(SearchContext);
  invariant(
    context !== undefined,
    'useSearch must be used inside SearchProvider',
  );
  return context;
}

export function useRecentSearchHistory(suffix: string, limit: number) {
  const [searches, setSearches] = useLocalStorage({
    defaultValue: [] as string[],
    key: `${LocalStorageKeys.RecentSearchHistoryPrefix}${suffix}`,
  });
  const addSearch = React.useCallback(
    (search: string) => {
      search = search.toLowerCase();
      setSearches((prev) => {
        const previousIndex = prev.indexOf(search);
        const current = [...prev];
        if (previousIndex !== -1) {
          current.splice(previousIndex, 1);
          current.unshift(search);
        } else {
          current.unshift(search);
          while (current.length > limit) {
            current.pop();
          }
        }
        return current;
      });
    },
    [limit, setSearches],
  );
  return [searches, addSearch] as const;
}

export default function (props: SearchBarProps) {
  const { placeholder, onClick, disabled } = props;

  const { search, setSearch } = useSearch();
  const { replace, pathname, query } = useRouter();

  const filteredQuery = Object.fromEntries(
    Object.entries(query as any).filter(([key]) => key !== 'q'),
  );

  const prevQuery = new URLSearchParams(filteredQuery as any).toString();

  const debounced = useDebouncedCallback((value) => {
    setSearch(value);
    if (!value) {
      replace(`${pathname}${prevQuery ? `?${prevQuery}` : ''}`);
    } else {
      replace(`${pathname}?${prevQuery ? `${prevQuery}&` : ''}q=${value}`);
    }
  }, 600);

  return (
    <div onClick={onClick} className={AppHeaderStyles.searchBarContainer}>
      <TextInput
        defaultValue={search}
        leftSection={<SearchIcon color={colors.borderPrimary} />}
        placeholder={placeholder}
        w="100%"
        noMargin
        onChange={(ev) => debounced(ev.target.value)}
        readOnly={disabled}
        classNames={{
          input: AppHeaderStyles.searchBarInput,
        }}
      />
    </div>
  );
}
