export { default as Flagafrica } from './Flag-Africa';
export { default as Flagasia } from './Flag-Asia';
export { default as Flagcaris } from './Flag-CaribbeanIslands';
export { default as Flageurope } from './Flag-Europe';
export { default as Flagglobal } from './Flag-Global';
export { default as Flaglatam } from './Flag-LatinAmerica';
export { default as Flagmena } from './Flag-Mena';
export { default as Flagmiddleeast } from './Flag-MiddleEast';
export { default as Flagnortam } from './Flag-NorthAmerica';
export { default as Flagoceania } from './Flag-Oceania';
export { default as Flagsea } from './Flag-SoutheastAsia';
