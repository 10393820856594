import { SessionStorageKeys } from 'common/constants/browser-storage-keys';
import {
  BrowserStorageType,
  useBrowserStorageSync,
} from 'hooks/use-browser-storage';
import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from 'next/types';
import React from 'react';

export interface SafeAreaPayload {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export const SafeAreaContext = React.createContext<SafeAreaPayload>({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export function SafeAreaProvider(
  props: { value?: SafeAreaPayload | null } & React.PropsWithChildren,
) {
  const safeArea = useBrowserStorageSync<SafeAreaPayload>(
    BrowserStorageType.Session,
    {
      fallback: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      key: SessionStorageKeys.SafeArea,
      value: props.value,
    },
  );

  return (
    <SafeAreaContext.Provider value={safeArea}>
      {props.children}
    </SafeAreaContext.Provider>
  );
}

export function getSafeAreaFromServerProps(
  context: GetServerSidePropsContext,
): GetServerSidePropsResult<{
  safeArea: SafeAreaPayload | null;
}> {
  const raw = context.req.headers['safearea'] as string;
  const json = raw ? JSON.parse(raw) : undefined;
  return {
    props: {
      safeArea: json
        ? {
            top: parseInt(json.top, 10),
            left: parseInt(json.left, 10),
            right: parseInt(json.right, 10),
            bottom: parseInt(json.bottom, 10),
          }
        : null,
    },
  };
}
