import { IconProps } from '@phosphor-icons/react';
import {
  SmartCustomizationUnit,
  applySmartCustomization,
} from 'common/repositories/customization';
import colors from 'common/styles/colors';
import { MaybeFC, MaybeFCType, MaybeText } from 'components/common/maybe';
import type { TextProps } from 'components/elements/text';
import { OptionalIndicator } from 'components/widgets/header/app';
import React from 'react';

import sideNavigationStyles from './side-navigation.styles.css';

type SideNavigationActionState = {
  active?: boolean;
};
export interface SideNavigationActionCustomizationProps {
  root?: SmartCustomizationUnit<
    SideNavigationActionState,
    React.ComponentProps<'button'>
  >;
  indicator?: React.ComponentProps<typeof OptionalIndicator>['customization'];
  icon?: SmartCustomizationUnit<SideNavigationActionState, IconProps>;
  text?: SmartCustomizationUnit<SideNavigationActionState, TextProps>;
}

interface SideNavigationActionProps extends SideNavigationActionState {
  onClick?(): void;
  indicator?: string | number;
  children: React.ReactNode;
  icon: MaybeFCType<IconProps>;
  customization?: SideNavigationActionCustomizationProps;
}

export default function SideNavigationAction(props: SideNavigationActionProps) {
  const {
    children,
    onClick,
    active = false,
    icon,
    indicator,
    customization,
  } = props;
  const state = { active };
  return (
    <button
      {...applySmartCustomization(
        state,
        {
          type: 'button',
          onClick,
          className: sideNavigationStyles.item,
        },
        [customization?.root],
      )}
    >
      <OptionalIndicator
        indicator={indicator}
        customization={customization?.indicator}
      >
        <MaybeFC
          props={applySmartCustomization(
            state,
            {
              weight: active ? 'fill' : 'regular',
              size: 24,
              color: active
                ? colors.foregroundPrimary
                : colors.foregroundTertiary,
            },
            [customization?.icon],
          )}
        >
          {icon}
        </MaybeFC>
      </OptionalIndicator>
      <MaybeText
        {...applySmartCustomization(
          state,
          {
            textVariant: 'body2Medium',
            textColor: active ? 'foregroundPrimary' : 'foregroundTertiary',
          },
          [customization?.text],
        )}
      >
        {children}
      </MaybeText>
    </button>
  );
}
