import { Loader } from '@mantine/core';
// import { RefreshIcon } from 'common/assets';
// import { mantineColors } from 'common/styles/default-colors';
// import Text from 'components/elements/text';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { errorViewStyles } from './style.css';
import Separator from '../separator';

export interface ErrorViewComponentProps {
  isLoading?: boolean;
  title?: string;
  message?: string;
  refetch?: () => void;
  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
  };
}

export default function ErrorViewComponent(props: ErrorViewComponentProps) {
  const { t } = useTranslation();
  const { title, message, isLoading, refetch, customization } = props;

  return (
    <div
      {...applyCustomization({ className: errorViewStyles.container }, [
        customization?.root,
      ])}
      onClick={() => {
        if (!isLoading) {
          refetch && refetch();
        }
      }}
    >
      <div className={errorViewStyles.content}>
        <Text textVariant="h3">{title || t('error:load_failed_title')}</Text>
        {message && (
          <Text textVariant="body1Regular" textColor="sentimentNegative">
            {message}
          </Text>
        )}
        <Separator gap={8} />
        {isLoading ? (
          <Loader size={30} />
        ) : (
          <Button
            variant={{
              variant: 'tertiary',
              size: 'defaultLink',
            }}
          >
            {t('error:load_failed_prompt')}
          </Button>
        )}
      </div>
    </div>
  );
}
