import React from 'react';

import useKurosimNavigation, {
  StackNavigation,
  TabNavigation,
} from './use-kurosim-navigation';
import useGetParentRef, { ParentRefType } from './use-parent-ref';

export default function useKurosimParentScroll() {
  const ref = useGetParentRef(ParentRefType.Scroll);
  const { currentRoute } = useKurosimNavigation();
  const pathname = currentRoute()?.pathname;

  const onScroll = React.useCallback(() => {
    const current = currentRoute();
    TabNavigation.update(pathname, {
      ...current,
      scrollY: ref?.current?.scrollTop,
    });
    StackNavigation.update(pathname, {
      ...current,
      scrollY: ref?.current?.scrollTop,
    });
  }, [currentRoute, pathname, ref]);

  //bind scroll
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const element = ref.current;
    const tabScrollY = TabNavigation.get().find((tab) => {
      const result = tab.pathname.includes(pathname);
      return result;
    })?.scrollY;

    element?.addEventListener('scrollend', onScroll);

    if (typeof tabScrollY === 'number') {
      element?.scroll({ top: tabScrollY });
      return () => {
        element?.removeEventListener('scrollend', onScroll);
      };
    }

    const stackScrollY = StackNavigation.get().find((stack) => {
      const result = stack.pathname.includes(pathname);
      return result;
    })?.scrollY;

    if (typeof stackScrollY === 'number') {
      element?.scroll({ top: stackScrollY });
    }

    return () => {
      element?.removeEventListener('scrollend', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
}
