import { Group } from '@mantine/core';
import { produce } from 'immer';
import { useContext } from 'react';
import { useController } from 'react-hook-form';

import MainFilePicker from './main-file-picker';
import PreviewFilePicker from './preview-file-picker';
import { FormContext } from '../form/context';
import FormControl from '../form-control';

export interface MultiFilePickerProps {
  name: string;
  type: 'files';
  label?: string;
  valueKey: string;
  maxWidth?: string;
  disabled?: boolean;
  required?: boolean;
  noMargin?: boolean;
  previewKey: string;
  resizeWidth: number;
  noErrorText?: boolean;
  resizeImage?: boolean;
  resizeHeight: number;
  onAfterChange?: (files: any) => void;
}

export default function MultiFilePicker(props: MultiFilePickerProps) {
  const {
    label,
    valueKey,
    noMargin,
    required,
    maxWidth,
    previewKey,
    resizeImage,
    resizeWidth,
    resizeHeight,
    onAfterChange,
  } = props;

  const context = useContext(FormContext);
  const { field, fieldState } = useController({ name: props.name });

  const disabled = props.disabled || !context.editable;

  const handleDeleteFile = (index) => {
    const newFiles = produce(field.value, (draft) => {
      draft.splice(index, 1);
    });
    field.onChange(newFiles);
    onAfterChange?.(newFiles);
  };

  const handleAddUpdateFile = (
    files: { filename: string; url: string }[],
    index?: number,
  ) => {
    const newFiles = produce(field.value || [], (draft) => {
      files.map((item) => {
        if (index !== undefined) {
          draft[index][valueKey] = item.filename;
          draft[index][previewKey] = item.url;
        } else {
          draft.push({
            [valueKey]: item.filename,
            [previewKey]: item.url,
          });
        }
      });
    });
    field.onChange(newFiles);
    onAfterChange?.(newFiles);
  };

  const _onPicked = (
    files: { filename: string; url: string }[],
    idx: number,
  ) => {
    handleAddUpdateFile(files, idx);
  };

  return (
    <FormControl
      label={label}
      noMargin={noMargin}
      required={required}
      error={fieldState?.error?.message}
    >
      <div style={{ maxWidth }}>
        {Array.isArray(field.value) && (
          <Group gap={8}>
            {field.value.map((item, idx) => (
              <>
                <PreviewFilePicker
                  isMultiple
                  disabled={disabled}
                  resizeImage={resizeImage}
                  resizeWidth={resizeWidth}
                  resizeHeight={resizeHeight}
                  preview={item[props.previewKey]}
                  onDelete={() => handleDeleteFile(idx)}
                  onPicked={(files) => _onPicked(files, idx)}
                />
              </>
            ))}
          </Group>
        )}
        <MainFilePicker
          isMultiple
          disabled={disabled}
          resizeImage={resizeImage}
          resizeWidth={resizeWidth}
          resizeHeight={resizeHeight}
          onPicked={(files) => handleAddUpdateFile(files)}
        />
      </div>
    </FormControl>
  );
}
