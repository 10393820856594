import {
  ActionIcon,
  ActionIconProps,
  Indicator,
  IndicatorProps,
  Tooltip,
} from '@mantine/core';
import { IconProps } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ArrowLeftIcon } from 'common/assets';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import colors from 'common/styles/colors';
import {
  MaybeFC,
  MaybeFCType,
  MaybeText,
  OptionalWrapper,
} from 'components/common/maybe';
import { PromiseActionIcon } from 'components/elements/button/promise';
import Text, { TextProps } from 'components/elements/text';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import MobileContainer from 'modules/components/mobile-container';
import React from 'react';
import structuralStyles from 'styles/layout.css';

import AppHeaderStyles from './app.styles.css';
import SearchBar from './search-bar';
import ResponsiveAppActionComponent from '../layout/actions';
import {
  AppLayoutActionsContext,
  DefaultAppLayoutActionKey,
} from '../layout/actions/context';
import { AppActionType, IAppAction } from '../layout/actions/types';

interface AppHeaderCustomization {
  root?: CustomizationUnit<React.ComponentProps<'div'>> & {
    noBorder?: boolean;
    dynamicSize?: boolean;
  };
  main?: CustomizationUnit<React.ComponentProps<'div'>>;
  back?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
    icon?: ActionIconProps;
  };
  actions?: CustomizationUnit<React.ComponentProps<'div'>>;
  title?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
    text?: CustomizationUnit<TextProps>;
  };
}
export interface AppHeaderActionCustomizationProps {
  indicator?: CustomizationUnit<IndicatorProps>;
  actionIcon?: CustomizationUnit<ActionIconProps>;
}

export interface AppHeaderProps {
  back?: boolean;
  actions?: React.ReactNode;
  title?: React.ReactNode;
  customization?: AppHeaderCustomization;
  extendedComponent?: React.ReactNode;
  onGoBack?: () => void;

  /** If this is not undefined, all default actions except for those specified in the provided array is filtered out. */
  allowedDefaultActions: DefaultAppLayoutActionKey[];
}

interface AppHeaderActionProps {
  onClick?: () => void | Promise<void>;
  children?: MaybeFCType<IconProps>;
  indicator?: number | string;
  customization?: AppHeaderActionCustomizationProps;
  tooltip?: React.ReactNode;
}

export const APP_HEADER_ICON_SIZE = 24;

function AppHeader(props: AppHeaderProps) {
  const {
    back,
    title,
    actions,
    customization,
    extendedComponent,
    onGoBack,
    allowedDefaultActions,
  } = props;
  const { back: goBack } = useKurosimNavigation();
  const __regularActions = React.useContext(AppLayoutActionsContext).filter(
    (action) => action.type === AppActionType.Regular,
  );
  const small = useIsSmallScreen();
  let distributedActions: IAppAction[] = [];
  if (small) {
    distributedActions = __regularActions.filter((action) =>
      allowedDefaultActions.find((actionKey) => actionKey === action.key),
    );
  }

  return (
    <div
      {...applyCustomization(
        {
          className: classNames(
            AppHeaderStyles.root,
            AppHeaderStyles.headerSize({
              dynamic: customization?.root?.dynamicSize ?? false,
            }),
            customization?.root?.noBorder
              ? undefined
              : AppHeaderStyles.headerBorder,
          ),
        },
        [customization?.root],
      )}
    >
      <MobileContainer
        style={{
          position: 'relative',
        }}
      >
        <div
          {...applyCustomization(
            {
              className: classNames(
                structuralStyles.flexbox({
                  align: 'stretch',
                  justify: back != null || title != null ? 'between' : 'end',
                }),
                structuralStyles.padding({ horizontal: 16 }),
              ),
            },
            [customization?.main],
          )}
        >
          {back && (
            <div
              {...applyCustomization({ className: AppHeaderStyles.back }, [
                customization?.back?.root,
              ])}
            >
              <ActionIcon
                {...customization?.back?.icon}
                onClick={onGoBack ? onGoBack : goBack}
                variant="transparent"
              >
                <ArrowLeftIcon size={APP_HEADER_ICON_SIZE} />
              </ActionIcon>
            </div>
          )}
          {title && (
            <div
              {...applyCustomization(
                {
                  className: AppHeaderStyles.titleContainer,
                },
                [customization?.title?.root],
              )}
            >
              <MaybeText
                {...applyCustomization(
                  {
                    className: classNames(
                      structuralStyles.fill({ width: true }),
                      structuralStyles.text({
                        overflow: 'ellipsis',
                        wrap: false,
                      }),
                    ),
                  },
                  [customization?.title?.text],
                )}
                textVariant="h2"
              >
                {title}
              </MaybeText>
            </div>
          )}
          {(!!actions || distributedActions.length > 0) && (
            <div
              {...applyCustomization({ className: AppHeaderStyles.actions }, [
                customization?.actions,
              ])}
            >
              {actions}
              {distributedActions.map((action) => (
                <ResponsiveAppActionComponent {...action} key={action.key} />
              ))}
            </div>
          )}
        </div>
        {extendedComponent}
      </MobileContainer>
    </div>
  );
}

interface OptionalTooltipProps {
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  customization?: CustomizationUnit<React.ComponentProps<typeof Tooltip>>;
}

function OptionalTooltip(props: OptionalTooltipProps) {
  const { tooltip, children, customization } = props;
  return (
    <OptionalWrapper
      Wrapper={
        tooltip
          ? ({ children, ...restProps }) => (
              <Tooltip {...restProps}>
                <div>{children}</div>
              </Tooltip>
            )
          : undefined
      }
      wrapperProps={applyCustomization(
        {
          label: tooltip,
          children,
        },
        [customization],
      )}
    >
      {children}
    </OptionalWrapper>
  );
}

interface OptionalIndicatorProps {
  indicator?: string | number;
  children?: React.ReactNode;
  customization?: CustomizationUnit<React.ComponentProps<typeof Indicator>>;
}

export function OptionalIndicator(props: OptionalIndicatorProps) {
  const { indicator, children, customization } = props;
  return (
    <OptionalWrapper
      Wrapper={indicator ? Indicator : undefined}
      wrapperProps={{
        label: (
          <Text textColor="mainWhite" textVariant="body3Medium">
            {typeof indicator === 'number'
              ? indicator === 0
                ? undefined
                : `${Math.min(9, indicator)}${indicator > 9 ? '+' : ''}`
              : indicator}
          </Text>
        ),
        ...applyCustomization(
          {
            zIndex: '2',
            className: AppHeaderStyles.actionIconIndicatorStyles,
            size: 16,
            offset: 2,
            color: colors.backgroundNotification,
          },
          [customization],
        ),
      }}
    >
      {children}
    </OptionalWrapper>
  );
}

function AppHeaderAction(props: AppHeaderActionProps) {
  const { children, onClick, indicator, customization, tooltip } = props;
  return (
    <OptionalTooltip tooltip={tooltip}>
      <OptionalIndicator
        indicator={indicator}
        customization={customization?.indicator}
      >
        <PromiseActionIcon
          onClick={onClick}
          variant="transparent"
          size={APP_HEADER_ICON_SIZE}
          {...applyCustomization({}, [customization?.actionIcon])}
        >
          <MaybeFC
            props={{
              size: APP_HEADER_ICON_SIZE,
              color: colors.backgroundDark,
            }}
          >
            {children}
          </MaybeFC>
        </PromiseActionIcon>
      </OptionalIndicator>
    </OptionalTooltip>
  );
}

(AppHeader as any).Action = AppHeaderAction;
(AppHeader as any).SearchBar = SearchBar;

export default AppHeader as typeof AppHeader & {
  Action: typeof AppHeaderAction;
  SearchBar: typeof SearchBar;
};
