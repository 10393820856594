import { AssetPaths } from 'common/constants/assets';
import React from 'react';

// import * as flags from '../flags';

interface Props extends React.ComponentProps<'img'> {
  iso: string;
  borderRadius?: number | string;
  round?: boolean;
}

const Flags = React.memo((props: Props) => {
  const {
    iso,
    borderRadius,
    style,
    width,
    height,
    round = false,
    ...rest
  } = props;

  if (!iso) {
    return null;
  }

  const code = iso;
  const src = `${AssetPaths.Flags}/${code.toLowerCase()}.webp`;
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      {...rest}
      src={src}
      alt={iso}
      width={width ?? (round ? 24 : 32)}
      height={height ?? 24}
      style={{
        borderRadius: borderRadius ?? (round ? '50%' : 4),
        // imageRendering: 'crisp-edges',
        objectFit: 'cover',
        ...style,
      }}
      //https://stackoverflow.com/a/77280293
      onError={(event: any) => {
        event.target.id = AssetPaths.Fallback;
        event.target.srcset = AssetPaths.Fallback;
      }}
    />
  );

  // const FlagComponent = flags[`Flag${iso.toUpperCase()}`];

  // if (!FlagComponent) {
  //   return iso;
  // }

  // return (
  //   <FlagComponent
  //     {...rest}
  //     width={width ?? (round ? 24 : 32)}
  //     height={height ?? 24}
  //     style={{
  //       borderRadius: borderRadius ?? (round ? '50%' : 4),
  //       imageRendering: 'crisp-edges',
  //       ...style,
  //     }}
  //   />
  // );
});

export default Flags;
