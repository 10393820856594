import * as regions from './region-flags';

interface Props extends React.ComponentProps<'img'> {
  iso: string;
  borderRadius?: number | string;
}

export default function Regions(props: Props) {
  const { iso, borderRadius, style, ...rest } = props;

  if (!iso) {
    return null;
  }

  const RegionComponent = regions[`Flag${iso.trim().toLowerCase()}`];
  if (!RegionComponent) {
    return iso;
  }

  return <RegionComponent {...rest} style={{ borderRadius, ...style }} />;
}
