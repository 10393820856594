import * as React from 'react';
import { FieldValues, WatchObserver, useFormContext } from 'react-hook-form';
import { Options as DebounceOptions, useDebouncedCallback } from 'use-debounce';

interface DebounceWatcherProps<TFormType> extends DebounceOptions {
  wait?: number;
  children: (values: TFormType) => React.ReactNode;
}

export default function useDebounceValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const [debounceLoading, setDebounceLoading] = React.useState(false);
  React.useEffect(() => {
    setDebounceLoading(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setDebounceLoading(false);
    }, delay);
    return () => {
      clearTimeout(handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { debouncedValue, debounceLoading };
}

/**
 * useWatch and watch with names trigger component rerenders, which means that while debouncing their values will still cause the component to rerender.
 * @see https://www.react-hook-form.com/api/useform/watch/
 * @see https://github.com/react-hook-form/react-hook-form/issues/1905
 */
export function useDebouncedWatch<TFormType extends FieldValues>(
  fn: WatchObserver<TFormType>,
  wait: number,
  options?: DebounceOptions,
) {
  const { watch } = useFormContext<TFormType>();
  const debouncedFn = useDebouncedCallback(fn, wait, {
    leading: false,
    ...options,
  });
  React.useEffect(() => {
    const subscription = watch(debouncedFn);
    return () => subscription.unsubscribe();
  }, [watch, debouncedFn]);
}

/** A hook that returns an object for interacting with the debounce callback subscriptions. This is useful if you want to have multiple debounced callbacks, but they cannot be created at the top-level (cannot use hooks) */
export function useDebounceScheduler() {
  type ScheduledTask = {
    wait: number;
    timeoutId: NodeJS.Timeout;
  };
  const subscribers = React.useRef<Record<string, ScheduledTask>>(
    Object.create(null),
  );
  return React.useCallback((id: string, callback: () => void, wait: number) => {
    clearTimeout(subscribers.current[id]?.timeoutId);
    subscribers.current[id] = {
      wait,
      timeoutId: setTimeout(() => {
        callback();
        // Delete subscription after done
        delete subscribers.current[id];
      }, wait),
    };
  }, []);
}

/** HOC for useDebouncedWatch */
export function DebounceWatcher<TFormType extends FieldValues>(
  props: DebounceWatcherProps<TFormType>,
) {
  const { children, wait = 500, ...options } = props;
  const { getValues } = useFormContext<TFormType>();
  const [value, setValue] = React.useState(getValues());
  useDebouncedWatch<TFormType>(
    (values) => {
      setValue(values as TFormType);
    },
    wait,
    options,
  );
  return children(value);
}

export function useDebouncedEffect(
  fn: Parameters<typeof React.useEffect>[0],
  dependents: Parameters<typeof React.useEffect>[1],
  wait: number,
) {
  const effect = useDebouncedCallback(fn, wait, {
    leading: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(effect, dependents);
}
