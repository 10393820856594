import { IconProps } from '@phosphor-icons/react';
import { NavigationRoutes } from 'common/routes';
import { MaybeFCType } from 'components/common/maybe';
import { AppHeaderActionCustomizationProps } from 'components/widgets/header/app';
import React from 'react';

import { SideNavigationActionCustomizationProps } from '../side-navigation/actions';

export enum AppActionType {
  /** This action is visible in Side Navigation first group on desktop mode, and visible in Bottom Navigation Bar on mobile  */
  Navigation,
  /** This action is visible in Side Navigation second group on desktop mode, and visible in App Header on mobile */
  Regular,
  /** This action is only visible in the Side Navigation, and hidden on mobile */
  Convenience,
}

export interface IAppAction {
  type: AppActionType;
  key: string;
  children: React.ReactNode;

  // Behavior
  onClick?(): void;
  hidden?: boolean;

  /** Provides external data or hooks for the action */
  useProvideData?(props: IAppAction): IAppAction;

  // Appearance
  icon: MaybeFCType<IconProps>;
  /** ``label`` is used for tooltip in AppHeaderAction. If ``children`` is a string and label is not defined, then ``children`` will be used as label. */
  label?: string;
  indicator?: string | number;
  headerCustomization?: AppHeaderActionCustomizationProps;
  sidenavCustomization?: SideNavigationActionCustomizationProps;
  additionalComponent?: React.ReactNode;

  // Routing
  /** If this is defined, this action will be in active state if the current pathname equals ``route``. Its default onClick handler will also be a redirect to that route. */
  route?: NavigationRoutes;
  /** If this is defined, this action will be in active state if the current pathname starts with any of the prefixes */
  routePrefix?: string[];
}
